
import { Component, Vue } from "vue-property-decorator";
import Snackbar from "@/components/Snackbar.vue";
import AppHeader from "@/components/Header.vue";
import AppFooter from "@/components/Footer.vue";

import { AuthService } from "./services/authService";
@Component({
    components: {
        Snackbar,
        AppHeader,
        AppFooter,
    },
})
export default class App extends Vue {
    public get snackbar(): {
        text: string;
        color: string;
        value: string;
        trigger: boolean;
        timeout: number;
    } {
        return this.$store.state.snackbar;
    }

    public onSnackbarInput(value: boolean): void {
        this.$store.commit("setSnackbarValue", { value });
    }

    public hideSnackbar(): void {
        this.$store.commit("hideSnackbar");
    }

    // pwa stuff

    // refresh variables
    refreshing: boolean = false;
    registration: ServiceWorkerRegistration | null = null;
    updateExists: boolean = false;

    public created(): void {
        // Listen for our custom event from the SW registration
        document.addEventListener(
            "swUpdated",
            (event: Event) => {
                this.updateAvailable(event as CustomEvent);
            },
            {
                once: true,
            }
        );

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if (this.refreshing) return;
            this.refreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
        });
    }

    public async mounted(): Promise<void> {
        try {
            const conf = await AuthService.getConfig();
            this.$store.commit("setConfig", conf);
        } catch (error) {
            console.error(error);
        }
    }

    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    public updateAvailable(event: { detail: ServiceWorkerRegistration }): void {
        this.registration = event.detail;
        this.updateExists = true;
    }

    // Called when the user accepts the update
    public refreshApp(): void {
        this.updateExists = false;
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return;
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
}
