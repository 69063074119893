// import { CompanyData } from "@/interfaces/user";
import Config from "@/interfaces/config";
import axios, { AxiosResponse } from "axios";
import request from "axios";

export class AuthService {
    public static async login(
        email: string,
        password: string
    ): Promise<{ access_token: string; token_type: string }> {
        const uri = `/auth/jwt/login`;
        const params = new URLSearchParams();
        params.append("username", email);
        params.append("password", password);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        try {
            const response: {
                data: { access_token: string; token_type: string };
            } = await axios.post(uri, params, config);
            return {
                access_token: response.data.access_token,
                token_type: response.data.token_type,
            };
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }
    public static async register(
        email: string,
        fullName: string,
        password: string
    ): Promise<AxiosResponse> {
        try {
            const response = await axios.post(`/auth/register`, {
                email: email,
                full_name: fullName,
                password: password,
            });
            return response;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async registerWithInvitation(
        token: string,
        password: string
    ): Promise<AxiosResponse> {
        try {
            const response = await axios.post(
                `/api/users/flows/register-with-invitation`,

                { token, password }
            );
            return response;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async verify(token: string): Promise<AxiosResponse> {
        const response = await axios.post("/auth/verify", { token });
        return response;
    }

    public static async forgotPassword(email: string): Promise<AxiosResponse> {
        const response = await axios.post("/auth/forgot-password", { email });
        return response;
    }

    public static async resetPassword(
        password: string,
        token: string
    ): Promise<AxiosResponse> {
        const response = await axios.post("/auth/reset-password", {
            password,
            token,
        });
        return response;
    }

    public static async getConfig(): Promise<Config> {
        const response = await axios.get("/api/config");
        return response.data;
    }

    public static async autheliaAuthorize(): Promise<string> {
        const response = await axios.get("/auth/authelia/authorize");
        return response.data.authorization_url;
    }
    public static async autheliaCallback(
        code: string,
        state: string
    ): Promise<string> {
        const response = await axios.get("/auth/authelia/callback", {
            params: { code, state },
        });
        return response.data.access_token;
    }
}
