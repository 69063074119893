import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: "#506dff",
                background: "#2b2d34",
                accent: "#ef4ffa",
            },
        },
    },
    lang: {
        locales: {
            de,
            en,
        },
    },
});
