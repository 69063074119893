
import {
    Component,
    Emit,
    Model,
    Prop,
    PropSync,
    Vue,
    Watch,
} from "vue-property-decorator";

@Component
export default class Snackbar extends Vue {
    @Model("input") value!: boolean;
    @PropSync("trigger") triggerProp?: boolean;
    @Prop() timeout?: number;
    public timeoutHandle: number | null = null;

    @Emit() public input(v: boolean): void | boolean {
        if (v) {
            this.refreshTimeout();
        }
        return v;
    }

    @Watch("value")
    public onValueChange(value: boolean): void {
        if (value) {
            this.refreshTimeout();
        } else {
            if (this.timeoutHandle) {
                clearTimeout(this.timeoutHandle);
                this.timeoutHandle = null;
            }
        }
    }

    @Watch("trigger")
    public onTrigger(trigger: boolean): void {
        if (trigger) {
            this.input(true);
        }
        this.triggerProp = false;
    }

    public refreshTimeout(): void {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }
        this.timeoutHandle = setTimeout(
            () => this.input(false),
            this.timeout ?? 5000
        );
    }
}
